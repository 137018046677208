const config = {
  apiKey: "AIzaSyBWh96mSiV4P6k5RpGXBv91SEtyuOOD5fM",
  authDomain: "fabmobil-survey.firebaseapp.com",
  databaseURL:
    "https://fabmobil-survey-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "fabmobil-survey",
  storageBucket: "fabmobil-survey.appspot.com",
  messagingSenderId: "507913596848",
  appId: "1:507913596848:web:827f392c54bfbad100f075",
};

export default config;
